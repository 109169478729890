import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import BrandsFilter from "../components/brandsFilter"
import SizesFilter from "../components/sizesFilter"
import TypesFilter from "../components/typesFilter"
import ListSearchDiaper from "../components/listSearchDiaper"

const IndexPage = ({ data }) => {
	let typeValue = "tape"
	let sizeValue = "ss"
	let brandValue = "all"
	let shopValue = "all"
  // itemListからtxtがあるものだけを抽出し、allContentfulItems形で格納する。itemList[0]にばらす感じ。
  var allDiscountItems = {}
  allDiscountItems.edges = []

  for (var items of data.allContentfulItems.edges)
  {
    for (var item of items.node.itemList){
      var discountItem = {}
      discountItem.node = {
        brandId:items.node.brandId,
        sizeId:items.node.sizeId,
        subbrandId:items.node.subbrandId,
        typeId:items.node.typeId,
      }
      discountItem.node.itemList = []
      discountItem.node.itemList.push(item)
      allDiscountItems.edges.push(discountItem)
    }
  }

  // allDiscountItems.edgesを1枚当たりの価格が安い順に並び替える
  allDiscountItems.edges.sort(function(a,b){
    return a.node.itemList[0].per - b.node.itemList[0].per
  })
  const _filterItems = allDiscountItems.edges.filter(function(tempItem){
    if((brandValue === 'all') && (shopValue === 'all')){
      return (tempItem.node.typeId === typeValue) && (tempItem.node.sizeId === sizeValue)
    }
    else if((brandValue === 'all') && (shopValue !== 'all')){
      const _shopValue = shopValue === 'other' ? 'babiesrus' : shopValue;
      return (tempItem.node.typeId === typeValue) && (tempItem.node.sizeId === sizeValue) && (tempItem.node.itemList[0].shopId === _shopValue)
    }
    else if((brandValue !== 'all') && (shopValue === 'all')){
      return (tempItem.node.typeId === typeValue) && (tempItem.node.sizeId === sizeValue) && (tempItem.node.brandId === brandValue)
    }
    else if((brandValue !== 'all') && (shopValue !== 'all')){
      const _shopValue = shopValue === 'other' ? 'babiesrus' : shopValue;
      return (tempItem.node.typeId === typeValue) && (tempItem.node.sizeId === sizeValue) && (tempItem.node.brandId === brandValue) && (tempItem.node.itemList[0].shopId === _shopValue)
    }
    else{
      // ここは通らない
      return true
    }
  })
  const result = _filterItems.slice(0,10)

	return (
		<Layout>
  	  <Seo title="オムツランキング" />
			{/* <ListSearchDiaper typeValue="tape" sizeValue="ss" brandValue="all" shopValue="all" allTypesYaml={data.allTypesYaml} allSizesYaml={data.allSizesYaml} allBrandsYaml={data.allBrandsYaml} allSubbrandsYaml={data.allSubbrandsYaml} allContentfulItems={data.allContentfulItems} articleUrl={`https://hula-la.com`} articleTitle={`テープ 新生児サイズ`} /> */}
			<ListSearchDiaper typeValue="tape" sizeValue="ss" brandValue="all" shopValue="all" allTypesYaml={data.allTypesYaml} allSizesYaml={data.allSizesYaml} allBrandsYaml={data.allBrandsYaml} allSubbrandsYaml={data.allSubbrandsYaml} result={result} articleUrl={`https://hula-la.com`} articleTitle={`テープ 新生児サイズ`} />
			<TypesFilter types={['pants','tape','swim','pad']}/>
			<SizesFilter types={['pants','tape','swim','pad']}/>
			<BrandsFilter types={['pants','tape','swim','pad']}/>
			<div className="container" style={{padding: `1.5rem 1rem`}}>
				<h3 className="title is-5">オムツは一体どこが一番安いの！？</h3>
				<p>『オムツをネットで購入したいけど、どこで購入するのが一番安いの！？』こんな疑問を解決する為にこのサイトを立ち上げました。ネット通販でオムツを購入できる事はとても助かるのですが、欲しいオムツを1番安く購入できるところを探すのにとても苦労しました。もちろん、通販サイトの機能を利用して、検索キーワードで欲しいオムツを絞り込み、安い価格順に並べることはできます。しかし、オムツの販売は1パックあたりの枚数が違ったり、複数パックの場合や○枚増量など、お店ごとにオムツ全部の枚数が異なる為、単純に価格を比較でません。</p>
			</div>
			<div className="container" style={{padding: `1.5rem 1rem`}}>
				<h3 className="title is-5">安いオムツを探すには1枚あたりの価格で比較！！</h3>
				<p>安いオムツを探すには、お店毎に違うオムツの枚数と価格の関係を、同じ基準で比較しなければなりません。そこで一番良いのがオムツ1枚あたりの価格で比較する方法です。1枚あたりの価格がわかれば、他のお店を比較して安いのか高いのか判断ができます。一見すると、複数パックまとめて販売しているので安く感じるオムツでも、実はそんなに安くないというのも簡単にわかります。</p>
			</div>
			<div className="container" style={{padding: `1.5rem 1rem`}}>
				<h3 className="title is-5">1枚あたりの価格が安いものを提供！！</h3>
				<p>オムツ1枚あたりの価格で比較するのがいいと分かっていても、１つ１つを電卓で計算していたら、日が暮れてしまします。そこで、主要な通販サイトである楽天、Amazon Yahoo!ショッピング LOHACO ベビーザらス アカチャンホンポ auPAYマーケット 西松屋 カインズ コストコ コーナン ツルハドラッグで販売しているもので、1枚あたりの価格が安いものを日々自動的に調べてこのサイトで提供します。商品をきちんと特定する為に１つ１つの商品に割り当てられているJANコードを基に検索し、オムツ全部の枚数と税込価格から1枚あたりの価格を計算しています。</p>
			</div>
			<div className="container" style={{padding: `1.5rem 1rem`}}>
				<h3 className="title is-5">安いオムツを早く楽に手に入れよう！！</h3>
				<p>オムツはどこのお店で購入しても商品自体変わらないので少しでも安く購入したいものです。大型のショッピングモールなどを利用したり、スーパーのセール品で購入すれば安く購入する事はできますが、お子さんがいる時に外出して購入するのはとても難しいと思います。また、いろんな通販サイトを調べ回って安いお店を探すのもとても時間がかかります。ですので、是非このサイトで、できるだけ手間をかけずに、少しでも安くオムツを購入することができるのにお役に立てればと思っています。</p>
			</div>
		</Layout>			
  )
}

export const query = graphql`
	query {
		allTypesYaml {
			edges {
				node {
					cid
					description
				}
			}
		}
		allSizesYaml {
			edges {
				node {
					cid
					description
				}
			}
		}
		allBrandsYaml {
			edges {
				node {
					cid
					description
				}
			}
		}
		allSubbrandsYaml {
			edges {
				node {
					brand_id
					subbrand_id
					description
					types {
						type_id 
						sizes {
							size_id
							min
							max
							unit
							num	
						}
					}
				}
			}
		}
		allContentfulItems {
			edges {
				node {
					brandId
					typeId
					sizeId
					subbrandId
					itemList {
						num
						href
						shopId
						shop
						per
						per_org
						price
						price_org
						sendPrice
						souryou
						txt
						point
						star
						review
						reviewUrl
					}
				}
			}
		}
	}
`

export default IndexPage
